import { createSlice } from "@reduxjs/toolkit"
import { MissionProps, MISSIONS_TYPE } from "@/types/types.mission"

const initialState: MissionProps = {
    payload: {
        dialog: null,
        choice1: {
            text: null,
            key: null,
        },
        choice2: {
            text: null,
            key: null,
        },
    },
    viewState: 0,
    missionsViewState: 0,
    type: MISSIONS_TYPE.DEFAULT,
    showCypher: true,
}

export const missionSlice = createSlice({
    name: "missions",
    initialState,
    reducers: {
        setDialog: (state, action) => {
            state.payload = action.payload
        },
        setViewState: (state, action) => {
            state.viewState = action.payload
        },
        setMissionsViewState: (state, action) => {
            state.missionsViewState = action.payload.view
            state.type = action.payload.type
        },
        setShowCypher: (state, action) => {
            state.showCypher = action.payload
        },
    },
})

export const { setDialog, setShowCypher, setViewState, setMissionsViewState } = missionSlice.actions
